import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import BlogRoll from './BlogRoll';
// import PreviewCompatibleImage from './PreviewCompatibleImage';

const AllBlogs = () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 250)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                isFeatured
                coverImage
                tags {
                  tag
                }
                author
              }
            }
          }
        }
      }
    `}
    render={(data, count) => (
      <BlogRoll posts={data.allMarkdownRemark.edges} count={count} />
    )}
  />
);

export default AllBlogs;
