import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import Layout from '../components/Layout';
import AllBlogs from '../components/Blog/AllBlogs';
import useSiteMetadata from '../components/SiteMetadata';
import { ApplicationContext } from '../components/Contexts/ApplicationContext';

export const IndexPageTemplate = ({ coverImage }) => {
  const { title, description } = useSiteMetadata();
  const context = useContext(ApplicationContext);
  const mainContentRef = useRef(null);
  const scrollToMainContent = (e) => {
    if (typeof Element.prototype.scrollIntoView !== 'function') {
      return;
    }
    e.preventDefault();

    console.log(mainContentRef.current);
    mainContentRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Helmet>
        <body className={context.actions.getBodyClassNames('home-template')} />
      </Helmet>
      <header
        className={classNames('main-header', {
          'no-cover': !coverImage,
        })}
        style={{ backgroundImage: coverImage ? `url(${coverImage})` : null }}
      >
        <nav className="main-nav overlay clearfix">
          <a
            href="#menu"
            className="menu-button icon-menu"
            onClick={context.actions.toggleMenu}
          >
            <span className="word">Menu</span>
          </a>
        </nav>
        <div className="vertical">
          <div className="main-header-content inner">
            <h1 className="page-title">{title}</h1>
            <h2 className="page-description">{description}</h2>
          </div>
        </div>
        <a
          className="scroll-down icon-arrow-left"
          href="#content"
          onClick={scrollToMainContent}
        >
          <span className="hidden">Scroll Down</span>
        </a>
      </header>
      <main id="content" className="content" role="main" ref={mainContentRef}>
        <AllBlogs />
      </main>
    </>
  );
};

IndexPageTemplate.propTypes = {
  coverImage: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        coverImage={frontmatter.coverImage}
        title={frontmatter.title}
        description={frontmatter.description}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        coverImage
        title
        description
      }
    }
  }
`;
